
import React from 'react'
import CategorySelect from './elements/category-select'
import Logo from './elements/logo'

const Header = (props) => {
  const category = `${props.category}`;
  const { children } = props

  const pathname = props.location.pathname

  return (
    <div className="blue-gradient relative text-text-light py-6 px-8">
      <div className="container relative z-10 mx-auto md:px-4">
        <div className="text-center flex flex-col items-center md:flex-row md:justify-between">
          <div className="mb-5 md:mb-0">
            <Logo/>
          </div>

          {
            pathname !== '/' &&
            <CategorySelect smallSelect category={category} darkSelect={true} />
          }
        </div>

        { children ? children : null}
      </div>

      <div className="absolute pin hero-pattern opacity-5 pointer-events-none"></div>
    </div>
  )
}

export default Header
