import React from 'react'
import { StaticQuery, graphql } from "gatsby"

export default ({ data }) => (
  <StaticQuery
    query={query}
    render={data => (
      <a target="_blank" rel="noopener noreferrer" href={data.site.siteMetadata.social.facebook}>
        <i className="text-grey-light icon-facebook"></i>
      </a>
    )}
  />
)

const query = graphql`
  query {
    site {
      siteMetadata {
        social {
          facebook
        }
      }
    }
  }
`
