import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const WebsiteSchema = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title,
              siteUrl
            }
          }
        }
      `}
        render={data => {
          const websiteSchema = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": data.site.siteMetadata.title,
            "url": data.site.siteMetadata.siteUrl
          }

          const schema = JSON.stringify(websiteSchema).replace(/(&quot;)/g,"\"")

          return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: schema}}></script>
          )
        }
      }
    />
  )
}

export default WebsiteSchema
