import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const BusinessSchema = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title,
              siteUrl,
              social {
                facebook,
                twitter,
                instagram,
                pinterest
              }
            }
          }
        }
      `}
        render={data => {
          const siteUrl = data.site.siteMetadata.siteUrl;
          const softwareWagonLogo = `${siteUrl}/images/software-wagon-logo.png`;

          const BusinessSchema = {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "logo": softwareWagonLogo,
            "image":[
               softwareWagonLogo
            ],
            "@id": siteUrl,
            "name": "Software Wagon",
            "email": "hello@softwarewagon.com",
            "url": siteUrl,
            "telephone": [
               " +61894687310"
            ],
            "vatID": "51 607 716 718",
            "openingHours": "Mo,Tu,We,Th,Fr 08:30-17:00",
            "sameAs":[
              data.site.siteMetadata.social.facebook,
              data.site.siteMetadata.social.twitter,
              data.site.siteMetadata.social.instagram,
              data.site.siteMetadata.social.pinterest
            ]
          }

          const schema = JSON.stringify(BusinessSchema).replace(/(&quot;)/g,"\"")

          return (
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: schema}}></script>
          )
        }
      }
    />
  )
}

export default BusinessSchema
