import React from 'react';
import PostCardEmpty from './elements/post-card-empty'

const SuggestionsPanel = () => (
  <div className="text-grey-darkest relative">
    <div className="container relative z-10 mx-auto text-center flex flex-col items-center pb-12 px-8">
      <PostCardEmpty />

      <p className="text-xs text-text-darker">
        <img src="/images/svgs/gift.svg" alt="gift icon" className="mr-2" width="20px" height="20px" style={{ verticalAlign: 'middle' }} /> Credits:  Logo - <a className="text-text-darker" href="//flaticon.com" target="_blank" rel="noopener noreferrer nofollow">flaticon.com</a>, Illustrations - <a className="text-text-darker" href="//undraw.co" target="_blank" rel="noopener noreferrer nofollow">undraw.co</a>, Background - <a className="text-text-darker" href="//heropatterns.com" target="_blank" rel="noopener noreferrer nofollow">heropatterns.com</a>
      </p>
    </div>
  </div>
)

export default SuggestionsPanel
