import React from 'react';
import { Link } from 'gatsby'
import LogoIcon from './elements/logo-icon'
import SocialLinks from './social-links'

const Footer = () => (
  <div className="bg-grey-darkest p-12 text-grey-light">
    <footer className="container mx-auto flex justify-between items-end lg:flex-row flex-col items-center">
      <div className="flex flex-col items-center mb-5 lg:mb-0 lg:items-end lg:flex-row">
        <Link className="mr-5 text-text-light tracking-wide text-lg no-underline flex items-end lg:mb-0 mb-5" to="/">
          <LogoIcon />

          Software Wagon
        </Link>

        <Link className="text-xs uppercase text-grey-light tracking-wide-as mx-3" to="/privacy-policy">Privacy Policy</Link>
      </div>

      <div className="mt-6 flex flex-col items-center lg:items-end lg:mt-0">
        <SocialLinks />

        <p className="text-xs leading-normal text-center">
          © Copyright {new Date().getFullYear()} - Made with <img src="/images/svgs/heart.svg" alt="heart icon"/> for the people of the internet.
        </p>
      </div>
    </footer>
  </div>
)

export default Footer
