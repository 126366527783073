import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import SuggestionsPanel from './suggestions-panel'
import WebsiteSchema from './json-schema/website-schema'
import BusinessSchema from './json-schema/business-schema'

import '../css/style.css'
import 'react-lazy-load-image-component/src/effects/blur.css';

function HeaderContent() {
  return null
}

class Layout extends React.Component {
  static HeaderContent = HeaderContent

  render() {
    const { children } = this.props
    const category = this.props.category ? this.props.category: '';
    const headerContent = children.find(child => child.key === 'header-content');

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title,
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              <Header siteTitle={data.site.siteMetadata.title} category={category} location={this.props.location}>
                {
                  headerContent ? headerContent : null
                }
              </Header>

              {
                children.filter(child => child.key === 'white-content').length ?
                <div className="bg-white">
                  <div className="container mx-auto pt-4 pb-12 px-4 leading-normal text-grey-darkest lg:pb-20 md:pt-10 md:px-8">
                    {children.filter(child => child.key === 'white-content')}
                  </div>
                </div> : null
              }

              <div className="container mx-auto pt-4 pb-12 px-4 leading-normal text-grey-darkest lg:pb-20 md:pt-10 md:px-8">
                {children.filter(child => child.key !== 'header-content' && child.key !== 'white-content')}
              </div>

              <SuggestionsPanel />

              <Footer />

              <WebsiteSchema />

              <BusinessSchema />
            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
