import React from "react"

class Select extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value ? props.value : '',
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({value: event.target.value})

    this.props.onChange(event.target.value)
  }

  render() {
    const placeholder = this.props.placeholder ? this.props.placeholder : 'Select'

    const options = this.props.options.map((option, index) =>
      <option key={index} value={option}>{option}</option>
    )

    let selectClasses = 'py-4 px-5 pr-12'
    let iconClasses = '';

    if (this.props.smallSelect) {
      selectClasses = 'py-2 px-3 pr-10'
    }

    if (this.props.darkSelect) {
      selectClasses += ' bg-white text-text-darker'
      iconClasses += ' text-text-darker'
    } else {
      selectClasses += ' bg-transparent text-white-darker'
      iconClasses += 'text-white-darker'
    }

    return (
      <div className="relative">
        <select
          onChange={this.handleChange}
          value={this.state.value}
          className={`focus:outline-none border border-white-darker tracking-wide appearance-none w-full text-sm ${selectClasses}`}
        >
          <option value="">{placeholder.toUpperCase()}</option>

          {options}
        </select>

        <i
          className={`icon-arrow absolute text-xs ${iconClasses}`}
          style={{
            top: `44%`,
            right: `22px`,
            fontSize: `5px`,
          }}
        ></i>
      </div>
    )
  }
}

export default Select;
