import React from 'react'

const PostCard = (props) => (
  <div className="w-full flex justify-center">
    <div className='px-5 mb-10 lg:w-1/2 md:w-full sm:w-full'>
      <div className="flex flex-col shadow-card bg-white rounded-b border border-dashed">
        <div className="flex flex-col md:items-start p-5">
          <h3 className="font-normal mb-3">Any Suggestions?</h3>

          <p>
            Send your feedback to <a href="mailto: hello@softwarewagon.com">hello@softwarewagon.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default PostCard
