import React from 'react'
import FacebookLink from './elements/facebook-link'
import TwitterLink from './elements/twitter-link'
import InstagramLink from './elements/instagram-link'
import PinterestLink from './elements/pinterest-link'

const SocialLinks = () => (
  <ul className="flex list-reset justify-end lg:mb-2 mb-4">
    <li className="mx-3">
      <FacebookLink />
    </li>

    <li className="mx-3">
      <TwitterLink />
    </li>

    <li className="mx-3">
      <InstagramLink />
    </li>

    <li className="ml-3">
      <PinterestLink />
    </li>
  </ul>
)

export default SocialLinks
