import React from 'react';
import Select from './select'
import { navigate } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

class CategorySelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: `${props.category}`,
      darkSelect: props.darkSelect ? props.darkSelect : false,
      selectOptions : props.options.map(cat => cat.title),
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (selectedOption) => {
    // Navigate to category page
    if (selectedOption !== '') {
      const category = this.props.options.find(cat => cat.title === selectedOption);

      navigate(`/${category.slug}`);
    }
  }

  render() {
    const { selectedOption } = this.state;
    const smallSelect = this.props.smallSelect ? this.props.smallSelect : false

    return (
      <Select
        smallSelect={smallSelect}
        onChange={this.handleChange}
        value={selectedOption}
        placeholder="Select a Category"
        options={this.state.selectOptions}
        darkSelect={this.state.darkSelect}
      />
    )
  }
}

export default (props) => {
  return (
    <StaticQuery
      query={
        graphql`
          query {
            allCategoriesJson {
              edges {
                node {
                  name
                  title
                  slug
                }
              }
            }
          }
        `
      }
      render={data => {
        const categories = data.allCategoriesJson.edges.map(category => category.node)
        const smallSelect = props.smallSelect ? props.smallSelect : false
        const darkSelect = props.darkSelect ? props.darkSelect : false

        return (
          <CategorySelect
            smallSelect={smallSelect}
            darkSelect={darkSelect}
            options={categories}
            category={props.category}
          />
        )
      }}
    />
  )
}
