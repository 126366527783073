import React from 'react';
import { Link } from 'gatsby'
import LogoIcon from './logo-icon'

const Logo = () => (
  <h2>
    <Link className="text-text-light font-normal no-underline flex items-center" to="/">
      <LogoIcon />

      Software Wagon
    </Link>
  </h2>
)

export default Logo
